import React, { useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";


const FactorChart = ({ data, setChartURI,selectedOption }) => {
  const hiddenChartRef = useRef(null);

  useEffect(() => {
    let timeoutId;

    if (data && hiddenChartRef.current && hiddenChartRef.current?.chart) {
      // Delay to ensure the chart has rendered
      timeoutId = setTimeout(() => {
        hiddenChartRef.current.chart
          .dataURI({ width: 2400, height: 1600, scale: 1 })
          .then(({ imgURI }) => setChartURI(imgURI));
      }, 500); // Adjust the delay as needed
    }

    // Clean up on component unmount
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [data, setChartURI]);


  if (!data) return null;
  // Process and sort the data
  const scaledData = data
    .map((item) => ({
      category: item.name,
      value:selectedOption == 'Raw' ? (item.average*10).toFixed(0): item.diff !== null ? Math.round(item.diff) : null,
    }))
    .filter((item) => item.value !== null)
    .sort((a, b) => b.value - a.value);
  const categories = scaledData.map((item) => item.category);
  const dataValues = scaledData.map((item) => item.value);
  


  const yaxisTitle = selectedOption == 'benchmark' ? "Difference to benchmark" : selectedOption == 'change'? 'Score change':"Average Score";
  const processedSeries = [{ name: yaxisTitle, data: dataValues }];

  const options = {
    ...baseOptions,
    chart: { ...baseOptions.chart, height: 400 },
    colors: scaledData.map((item) => (item.value >= 0 ? "#27CDA7" : "#F15062")),
    xaxis: {
      categories,
      labels: { style: { fontFamily: "Raleway, sans-serif" } },
    },
    yaxis: {
      title: { text: yaxisTitle },
      labels: { style: { fontFamily: "Raleway, sans-serif" } },
    },
    tooltip: {
      ...baseOptions.tooltip,
      y: { formatter: (val) => val.toString() },
    },
  };


  const hiddenChartOptions = {
    ...baseOptions,
    chart: {
      ...baseOptions.chart,
      id: "report-chart",
      width: 2400,
      height: 1600,
      animations: { enabled: false },
    },
    colors: scaledData.map((item) => (item.value >= 0 ? "#27CDA7" : "#F15062")),
    plotOptions: {
      bar: { horizontal: false, borderRadius: 10, barHeight: "70%",distributed: true },
    },
    grid: {
      ...baseOptions.grid,
      padding: { top: -1, right: 100, bottom: 300, left: 100 },
    },
    dataLabels: {
      ...baseOptions.dataLabels,
      style: {
        fontSize: 40,
      },
    },
    xaxis: {
      type: "category",
      categories,
      title: {
        text: "",
        offsetY: 200,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
      },
      labels: {
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
        offsetY: 20,
        rotate: -45,
        maxHeight: 100,
        formatter: (val) =>
          val.length > 30 ? `${val.substring(0, 27)}...` : val,
      },
    },
    yaxis: {
      labels: {
        offsetX: 80,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
        formatter: (value) => value.toFixed(0),
      },
      title: {
        text: "Average Score",
        offsetX: -30,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 30 },
      },
      tickAmount: 5,
        },
    tooltip: {
      ...baseOptions.tooltip,
      y: { formatter: (value) => value.toFixed(1) },
    }
  };

  console.log(hiddenChartOptions,options)

  return (
    <Container>
      {dataValues.length > 0 && (
        <>
          <Chart options={options} series={processedSeries} type="bar" height={400} />
          <HiddenChartContainer>
            <Chart
              ref={hiddenChartRef}
              options={hiddenChartOptions}
              series={processedSeries}
              type="bar"
              height={1600}
              width={2400}
            />
          </HiddenChartContainer>
        </>
      )}
    </Container>
  );
};

export default FactorChart;

const Container = styled.div`
    margin-top: 20px;
    position: relative;
`

const HiddenChartContainer = styled.div`
  position: absolute;
  top: -10000px; /* Position it off-screen */
  left: -10000px;
  width: 2400px; /* Desired high resolution width */
  height: 1600px; /* Desired high resolution height */
  overflow: hidden; /* Prevent scrollbars */
`;


const baseOptions = {
    chart: {
      type: "bar",
      fontFamily: "Raleway, sans-serif",
      foreColor: "#7E7E7E",
      zoom: { enabled: false },
      toolbar: { show: false },
      background: "transparent",
    },
    plotOptions: {
      bar: { horizontal: false, borderRadius: 5, barHeight: "70%", distributed: true, },
    },
    legend: { show: false },
    fill: { type: "solid" },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`, // Display percentage without decimals
      style: {
        colors: ["#fff"], // White text
        fontSize: "14px",
        fontFamily: "Raleway, sans-serif",
      },
    },
    tooltip: {
      enabled: true,
      style: { fontFamily: "Raleway, sans-serif" },
    },
  };