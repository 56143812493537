import styled,{keyframes} from "styled-components";
import React, { useEffect, useState } from "react";
import FactorRow from './FactorRow';
import { useSelector, shallowEqual } from "react-redux";
import Anchor from "app/audit/simple/Anchor";
import { calculateHeatmap,vertical_sorted_names,horizontal_sorted_names,factorTitleSorted,addEQ } from "./calculations";
import ItemRow from "./ItemRow";
import Empty from "../components/EmptyFeedback";

const Heatmap = () =>{
    const [selected, setSelected] = useState(null);
    const [outcomeQ, setOutcomeQ] = useState(null);
    const [outcomeStandards, setOutcomeStandards] = useState(null);
    const [organizationData, setOrganizationData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [verticalTitles, setVerticalTitles] = useState([]);
    const [horizontalTitles, setHorizontalTitles] = useState([]);
    const [factorTitles, setFactorTitles] = useState([]);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(10);
    const [dataFormat, setDataFormat] = useState();

    const core_data = useSelector(
      (state) => state.audit?.core_data,
      shallowEqual
    );
    const get_organizations = useSelector((state) => state.organizations);
    const get_auth = useSelector((state) => state.auth);
    const get_survey_questions = useSelector((state) => state.surveyquestions);

    const filtered_data = useSelector(
      (state) => state.audit?.filtered_data,
      shallowEqual
    );

    const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

    useEffect(() => {
      if (filtered_data && filtered_data.length > 0) {
        if (Array.isArray(filtered_data[0])) {
          setDataFormat(filtered_data);
        } else {
          setDataFormat([filtered_data]);
        }
      }
    }, [filtered_data]);

    useEffect(() => {
      if (core_data && dataFormat && anchor) {
        // const modified_data = addEQ(filtered_data)
        let rule = core_data?.Whitelabel?.rule
          ? parseInt(core_data?.Whitelabel?.rule)
          : 2;
        let data = calculateHeatmap(
          core_data,
          dataFormat,
          anchor,
          outcomeQ,
          outcomeStandards
        ).filter((f) => f.responses > rule);
        setTableData(data);

        setVerticalTitles(vertical_sorted_names(data));
        setHorizontalTitles(horizontal_sorted_names(data));
        setFactorTitles(factorTitleSorted(data));

        let min_score = 100;
        data.map((item) => {
          let score = item.culture_scores.overall_scores;
          if (score < min_score) {
            min_score = score;
          }
          Object.keys(item.outcome_scores.overall_scores).map((name) => {
            let score = item.outcome_scores.overall_scores[name];
            if (score < min_score) {
              min_score = score;
            }
          });
        });

        let max_score = 0;
        data.map((item) => {
          let score = item.culture_scores.overall_scores;
          if (score > max_score) {
            max_score = score;
          }
          Object.keys(item.outcome_scores.overall_scores).map((name) => {
            let score = item.outcome_scores.overall_scores[name];
            if (score > max_score) {
              max_score = score;
            }
          });
        });

        setMin(min_score * 10);
        setMax(max_score * 10);
      }
    }, [core_data, dataFormat, anchor, outcomeQ, outcomeStandards]);

    useEffect(() => {
      if (get_organizations) {
        setOrganizationData(
          get_organizations[
            get_auth?.organization_id || get_organizations?.organization?.id
          ]
        );
      }
    }, [get_organizations]);

    useEffect(() => {
      if (organizationData) {
        // We need to get the outcome question id
        // Then parse the outcome question list for the proper version
        const sortOrder = organizationData?.styling?.survey_sequence?.find(
          (item) => item.value === "outcome_question"
        )?.question?.sort_order;
  
        if (sortOrder) {
          const outcomeQ = get_survey_questions?.outcome_questions.find(
            (item) => item.sort_order === sortOrder
          );
          setOutcomeQ(outcomeQ);
        }
      }
    }, [core_data, organizationData]);

    useEffect(() => {
      if (outcomeQ) {
        setOutcomeStandards(
          core_data?.get_standards.find((f) =>
            f.question_sort_order.includes(outcomeQ.sort_order)
          )?.response
        );
      }
    }, [outcomeQ]);
  


    const selectRow = (index) => {
        if (selected === index) {
          setSelected(null);
        } else {
          setSelected(index);
        }
      }

    const findByOptionName = (name) => {
        return tableData.find((item) => item.option === name);
      }

    const findEQScore = (title,item) =>{
      if(title.name == 'Emotional Intelligence'){
        return (tableData.find((i) => i.option === item)?.EQ_scores.Overall*10).toFixed(0)
    }

    if(title.name == 'Emotional Intelligence 360'){
        return (tableData.find((i) => i.option === item)?.EQ_scores.Overall*10).toFixed(0)
    }
    return ''
    }

      const getScores = (title,item) => {
        const score = (findByOptionName(item)?.outcome_scores.overall_scores[title.name]*10).toFixed(0)
        if (score && score !== 'NaN') {
          return score;
        }
        return '';

      }

      const getColorGrade = (score) => {
        if(!score){
            return '#fff'
        }
         // Normalize the score to a value between 0 and 1
    let normalizedScore = (score - min) / (max - min);

    if(score > max){
        normalizedScore = 1
    }

    if(score < min){
        normalizedScore = 0
    }
    

    // Define the RGB values for the colors
    const redStart = { r: 241, g: 80, b: 98 };  // #F15062
    const greenEnd = { r: 39, g: 205, b: 167 }; // #27CDA7

    let red, green, blue;

    if (normalizedScore <= 0.5) {
        // Interpolate between redStart and white (255, 255, 255)
        const ratio = normalizedScore * 2; // Scale between 0 and 1
        red = redStart.r + Math.floor((255 - redStart.r) * ratio);
        green = redStart.g + Math.floor((255 - redStart.g) * ratio);
        blue = redStart.b + Math.floor((255 - redStart.b) * ratio);
    } else {
        // Interpolate between white (255, 255, 255) and greenEnd
        const ratio = (normalizedScore - 0.5) * 2; // Scale between 0 and 1
        red = 255 + Math.floor((greenEnd.r - 255) * ratio);
        green = 255 + Math.floor((greenEnd.g - 255) * ratio);
        blue = 255 + Math.floor((greenEnd.b - 255) * ratio);
    }

    // Return the color in RGB format
    return `rgb(${red}, ${green}, ${blue})`;
      }

      const getTotalResponses = () => {
        return tableData.map((item) => item.responses).reduce((a, b) => a + b, 0)
      }


      if (core_data?.noData) {
        return (
          <Container>
            <Empty />
          </Container>
        );
      }

    return (
        <Container>
            <Title>Culture Heatmap</Title>
            <Description>
                The Culture Heatmap provides a visual representation of the culture and outcome scores for each category tag. The scores are based on aggregate scores from the survey. The heatmap is color-coded to show the relative scores for each organization, with red indicating a lower score and green indicating a higher score.
            </Description>

            <T2>Please select a category to change the data on the top horizontal axis.</T2>
            <Anchor/>

            <Content>

              {getTotalResponses() == 0 ? <T2>No data available</T2> :
            <Table>
            <thead>
        <tr>
          <TableHeader></TableHeader> {/* Empty header for first column */}
          <TableHeader>
            <SlantedHeader>Overall</SlantedHeader>
          </TableHeader>
          {verticalTitles.map((title) => (
            <TableHeader key={title}>
              <SlantedHeader>{title}</SlantedHeader>
            </TableHeader>
          ))  
          }
          {/* Add other headers as needed */}
        </tr>
      </thead>
      <tbody>

      <TableRow3 >
            <FirstColumnCellResponse>Number of responses</FirstColumnCellResponse>
            <TableCell2>{getTotalResponses()}</TableCell2>
           {verticalTitles.map((title) => (
            <TableCell2>{findByOptionName(title)?.responses}</TableCell2>
          ))}
            {/* Add other cells as needed */}
          </TableRow3>

          {
            horizontalTitles.map((title,index) => {
              return (
                <>
                  <TableRow>
                <FirstColumnCell onClick={()=>selectRow(index)}>{title.name}</FirstColumnCell>
                <TableCell>{(title.score*10).toFixed(0)}</TableCell>

                  {title.name == "Culture" ? verticalTitles.map((item) => {
                    let value = (findByOptionName(item)?.culture_scores.overall_scores*10).toFixed(0)
                      return <TableCell color={getColorGrade(value)}>{value}</TableCell>               
                          
                    }) :

                            verticalTitles.map((item) => {
                              let value =  title.name == 'Emotional Intelligence' || title.name == 'Emotional Intelligence 360' ? findEQScore(title,item):getScores(title,item)
                              return <TableCell color={getColorGrade(value)}>{value}</TableCell>               
                          
                    })
                          }
            
              </TableRow>
                  {
                    title.name == "Culture" ? factorTitles?.map((factor) => {
                      return <FactorRow 
                              summary={factor} 
                              data={tableData} 
                              index={index} 
                              selected={selected}
                              min={min}
                              max={max} />
                    }) : <ItemRow 
                            summary={title} 
                            data={tableData} 
                            index={index} 
                            selected={selected}
                            min={min}
                            max={max}
                    />
                  }

              </>
              )
            })
          }

     
      </tbody>
    </Table> }
            </Content>
            

        </Container>
    )
}

export default Heatmap;


const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    font-family: 'Raleway';
    overflow-y:scroll;
    overflow-y: hidden; 

    /* Customize scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* Default color when not hovered */
    border-radius: 4px; /* Rounded corners */
  }

  /* Darker scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.6); /* Darker color on hover */
  }

  /* Hide horizontal scrollbar by default */
  ::-webkit-scrollbar {
    height: 0;
  }

  /* Show horizontal scrollbar on hover */
  &:hover::-webkit-scrollbar {
    height: 8px;
  }
`

const Title = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-top:20px;
    margin-bottom:15px;
    color:#2d70e2;
    text-transform:uppercase;
    display:flex;
`
const Description = styled.div`
    font-size:14px;
    font-weight:400;
    margin-bottom:30px;
    line-height:1.5;
    color:#2A3039;
    max-width:800px;
`

const T2 = styled.div`
  font-size:14px;
  font-weight:600;
  margin-bottom:10px;
`

const Table = styled.table`
  border-collapse: collapse;
  margin-top:50px;
`;

const TableHeader = styled.th`
  padding: 0;
  height: 40px;
  width: 40px;
  min-width:40px;
  vertical-align: bottom;
  position: relative;

`;

const SlantedHeader = styled.div`
  transform: rotate(-45deg);
  transform-origin: left bottom;
  white-space: nowrap;
  position: absolute;
  bottom: -35px; /* Adjust to align the text properly */
  left: 35px; /* Adjust to align the text properly */
  width: 175px; /* Adjust width based on rotation and content */
  height: 50px;
  min-height:40px;
  line-height: 1;
  border-top: 1px solid #DFDFDF;
  display:flex;
  padding-left: 35px;
 padding-top:10px;
 color:#2D70E2;
 font-weight:500;
  overflow: hidden;           /* Ensures the overflowed text is hidden */
  text-overflow: ellipsis;    /* Adds the ellipsis (...) */
`;

const TableRow = styled.tr`
 height: 35px;
  
`;

const TableRow3 = styled.tr`
 height: 35px;
 background-color: #F8FAFF;
  
`;


const TableRow2 = styled.tr`
    max-height:${props=>props.selected ? '200px' : '0px'};
    height: ${props=>props.selected ? '35px' : '0px'};


    overflow:hidden;
    transition:all 0.3s;
    

 
`;


const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 0;
  text-align: center;
  width: 50px;
  min-width:50px;
  height:100%;

  background-color: ${props => props.color || '#fff'};

`;

const TableCell2 = styled.td`
  border: 1px solid #ddd;
  padding: 0;
  text-align: center;
  width: 50px;
  min-width:50px;
  height:100%;
  font-size:12px;
  font-weight:600;

   background-color: #F8FAFF;

`;

const FirstColumnCell = styled.td`
  border: 1px solid #ddd;
  padding: 0 8px;
  text-align: left;
  width: 350px; /* Wider first column */
  min-width:350px;
 height:100%;
  background-color: #fff;
  
  font-size:14px;
  cursor:pointer;

  color:#2D70E2;
 font-weight:500;

`;

const FirstColumnCellResponse = styled.td`
  border: 1px solid #ddd;
  padding: 0 8px;
  text-align: left;
  width: 350px; /* Wider first column */
 height:100%;
  background-color: #F8FAFF;
  text-transform:uppercase;
  font-size:10px;
  cursor:pointer;
 font-weight:500;


`;


const Content = styled.div`
    border-radius:10px;
    margin-top:30px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:center;
    width:100%;
    height:100%;
    position:relative;
    margin-bottom:50px;

    min-height:250px;
`

const sampleData = [
    {
      category: 'Culture',
      overall: 75,
      customerService: 3,
      sales: -2,
      marketing: -5,
      operations: -15,
      // Add other fields as needed
    },
    {
      category: 'Innovation',
      overall: 76,
      customerService: 4,
      sales: -3,
      marketing: -6,
      operations: -16,
      // Add other fields as needed
    },
    {
        category: 'Engagement',
        overall: 76,
        customerService: 4,
        sales: -3,
        marketing: -6,
        operations: -16,
        // Add other fields as needed
      },
    {
        category: 'Emotional Intelligence',
        overall: 76,
        customerService: 4,
        sales: -3,
        marketing: -6,
        operations: -16,
        // Add other fields as needed
      },
    // Add more rows as needed
  ];

  
const expanded = [
    {
      category: 'Psychological Safety',
      overall: 75,
      customerService: 3,
      sales: -2,
      marketing: -5,
      operations: -15,
      // Add other fields as needed
    },
    {
      category: 'Clarity',
      overall: 76,
      customerService: 4,
      sales: -3,
      marketing: -6,
      operations: -16,
      // Add other fields as needed
    },
    {
        category: 'Communication',
        overall: 76,
        customerService: 4,
        sales: -3,
        marketing: -6,
        operations: -16,
        // Add other fields as needed
      },
    {
        category: 'Empathy',
        overall: 76,
        customerService: 4,
        sales: -3,
        marketing: -6,
        operations: -16,
        // Add other fields as needed
      },
    // Add more rows as needed
  ];